export const USER_AUTHENTICATING = "USER_AUTHENTICATING";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const USER_AUTHENTICATION_HAS_ERROR = "USER_AUTHENTICATION_HAS_ERROR";

export const USER_FETCHING = "USER_FETCHING";
export const USER_FETCHED = "USER_FETCHED";
export const USER_FETCH_HAS_ERROR = "USER_FETCH_HAS_ERROR";

export const BARCODES_FETCHING = "BARCODES_FETCHING";
export const BARCODES_FETCHED = "BARCODES_FETCHED";
export const BARCODES_FETCH_HAS_ERROR = "BARCODES_FETCH_HAS_ERROR";

export const BARCODE_FETCHING = "BARCODE_FETCHING";
export const BARCODE_FETCHED = "BARCODE_FETCHED";
export const BARCODE_FETCH_HAS_ERROR = "BARCODE_FETCH_HAS_ERROR";

export const BARCODE_UPDATING = "BARCODE_UPDATING";
export const BARCODE_UPDATED = "BARCODE_UPDATED";
export const BARCODE_UPDATE_HAS_ERROR = "BARCODE_UPDATE_HAS_ERROR";

export const BARCODE_DELETING = "BARCODE_DELETING";
export const BARCODE_DELETED = "BARCODE_DELETED";
export const BARCODE_DELETE_HAS_ERROR = "BARCODE_DELETE_HAS_ERROR";

export const BARCODE_CREATING = "BARCODE_CREATING";
export const BARCODE_CREATED = "BARCODE_CREATED";
export const BARCODE_CREATE_HAS_ERROR = "BARCODE_CREATE_HAS_ERROR";

export const BARCODE_PARTIES_FETCHING = "BARCODE_PARTIES_FETCHING";
export const BARCODE_PARTIES_FETCHED = "BARCODE_PARTIES_FETCHED";
export const BARCODE_PARTIES_FETCH_HAS_ERROR =
  "BARCODE_PARTIES_FETCH_HAS_ERROR";

export const PARTIES_FETCHING = "PARTIES_FETCHING";
export const PARTIES_FETCHED = "PARTIES_FETCHED";
export const PARTIES_FETCH_HAS_ERROR = "PARTIES_FETCH_HAS_ERROR";

export const PARTY_FETCHING = "PARTY_FETCHING";
export const PARTY_FETCHED = "PARTY_FETCHED";
export const PARTY_FETCH_HAS_ERROR = "PARTY_FETCH_HAS_ERROR";

export const PARTY_UPDATING = "PARTY_UPDATING";
export const PARTY_UPDATED = "PARTY_UPDATED";
export const PARTY_UPDATE_HAS_ERROR = "PARTY_UPDATE_HAS_ERROR";

export const PARTY_DELETING = "PARTY_DELETING";
export const PARTY_DELETED = "PARTY_DELETED";
export const PARTY_DELETE_HAS_ERROR = "PARTY_DELETE_HAS_ERROR";

export const PARTY_CREATING = "PARTY_CREATING";
export const PARTY_CREATED = "PARTY_CREATED";
export const PARTY_CREATE_HAS_ERROR = "PARTY_CREATE_HAS_ERROR";

export const PARTY_LINE_ITEMS_FETCHING = "PARTY_LINE_ITEMS_FETCHING";
export const PARTY_LINE_ITEMS_FETCHED = "PARTY_LINE_ITEMS_FETCHED";
export const PARTY_LINE_ITEMS_FETCH_HAS_ERROR =
  "PARTY_LINE_ITEMS_FETCH_HAS_ERROR";

export const PARTY_LINE_ITEM_DELETING = "PARTY_LINE_ITEM_DELETING";
export const PARTY_LINE_ITEM_DELETED = "PARTY_LINE_ITEM_DELETED";
export const PARTY_LINE_ITEM_DELETE_HAS_ERROR =
  "PARTY_LINE_ITEM_DELETE_HAS_ERROR";

export const EXCEL_FILE_CREATING = "EXCEL_FILE_CREATING";
export const EXCEL_FILE_CREATED = "EXCEL_FILE_CREATED";
export const EXCEL_FILE_CREATE_HAS_ERROR = "EXCEL_FILE_CREATE_HAS_ERROR";

export const PARTY_LINE_ITEM_CREATING = "PARTY_LINE_ITEM_CREATING";
export const PARTY_LINE_ITEM_CREATED = "PARTY_LINE_ITEM_CREATED";
export const PARTY_LINE_ITEM_CREATE_HAS_ERROR =
  "PARTY_LINE_ITEM_CREATE_HAS_ERROR";

export const TEMPLATES_DATA_FETCHING = "TEMPLATES_DATA_FETCHING";
export const TEMPLATES_DATA_FETCHED = "TEMPLATES_DATA_FETCHED";
export const TEMPLATES_DATA_FETCH_HAS_ERROR = "TEMPLATES_DATA_FETCH_HAS_ERROR";
