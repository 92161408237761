// eslint-disable-next-line import/no-anonymous-default-export
export default {
  requiredField: "Zorunlu Alan",
  atLeastEightCharacters: "En az 8 Karakter Girilmeli",
  corpText: "Teknik Apre Corp.",
  adminPanelLogin: "Admin Panel Giriş",
  signIn: "Giriş Yap",
  emailOrUsername: "Email / Kullanıcı Adı",
  password: "Şifre",
  teknikapre: "teknikapre",
  logout: "Çıkış Yap",
  login: "Giriş Yap",
  parties: "Partiler",
  partiesFetchError: "Partiler yüklenemedi!",
  search: "Arama...",
  reload: "Yenile",
  name: "İsim",
  netWeightDivisionNum: "Metre Kaça Bölünecek?",
  grossWeightAdditionNum: "Net Kiloya Kaç Eklenecek?",
  templateImage: "Şablon Resmi",
  operations: "İşlemler",
  update: "Güncelle",
  delete: "Sil",
  createParty: "Parti Oluştur",
  details: "Detaylar",
  viewDetails: "Detayları Görüntüle",
  partyDetailsError: "Parti detayları yüklenemedi!",
  partyUpdateSuccess: "Parti başarıyla güncellendi!",
  partyUpdateError: "Parti güncellenirken bir hata oluştu!",
  partyDeleteConfirm: "Bu partiyi silmek istediğinize emin misiniz?",
  partyDeleteSuccess: "Parti başarıyla silindi!",
  partyDeleteError: "Parti silinirken bir hata oluştu!",
  backToParties: "Partilere Dön",
  save: "Kaydet",
  cancel: "İptal Et",
  deleteParty: "Partiyi Sil",
  partyLineItemsError: "Partinin itemleri yüklenemedi!",
  openBarcode: "Barkodu Aç",
  rollNo: "Roll No",
  partyLineItemDeleteSuccess: "Parti item başarıyla silindi!",
  partyLineItemDeleteError: "Parti item silinirken bir hata oluştu!",
  createExcelFileError: "Excel dosyası oluşturulurken bir hata meydana geldi!",
  createPartyLineItemError:
    "Yeni parti item oluşturulurken bir hata meydana geldi!",
  createPartyLineItemSuccess: "Yeni parti item başarıyla oluşturuldu!",
  createExcelFile: "Excel Dosyası Oluştur",
  newRecord: "Yeni Kayıt",
  fillBlanks: "Lütfen gerekli alanları doldurunuz!",
  fetchBarcodeTemplatesError: "Barkod şablonları yüklenemedi!",
  partyCreateSuccess: "Parti başarıyla oluşturuldu!",
  partyCreateError: "Parti oluşturulurken hata meydana geldi!",
  partyName: "Parti Adı",
  enterPartyName: "Parti adını giriniz...",
  partyCode: "Parti Kodu",
  maxFiveCharacter: "(Maks. 5 karakter)",
  enterPartyCode: "Parti kodunu giriniz...",
  partyCodeDesc: "Barkod ve excel dosyasında kullanılır.",
  partyCodeExm: "Örn: Kırmızı için KRM",
  divisionNumber: "Bölünme Sayısı",
  enterDivisionNumber: "Bölünme sayısını giriniz...",
  divisonNumberDesc: "Metre kaça bölünecek?",
  additionNumber: "Ekleme Sayısı",
  enterAdditionNumber: "Ekleme sayısını giriniz...",
  additionNumberDesc: "Net kiloya kaç eklenecek?",
  additionNumberWarning:
    "Seçeceğiniz şablonda gross kg yoksa boş bırakabilirsiniz.",
  chooseTemplate: "Şablon Seçimi",
  enter: "giriniz...",
  barcode: "Barkod",
  templates: "Şablonlar",
  partyList: "Parti Listesi",
  createdDate: "Oluşturulma Zamanı",
  createBarcode: "Barkod Oluştur",
  barcodeList: "Barkod Listesi",
  barcodesFetchError: "Barkodlar yüklenemedi!",
  backToBarcodes: "Barkodlara Dön",
  barcodes: "Barkodlar",
  barcodeCreateSuccess: "Barkod başarıyla oluşturuldu!",
  barcodeCreateError: "Barkod oluşturulurken hata meydana geldi!",
  barcodeName: "Barkod Adı",
  enterBarcodeName: "Barkod adını giriniz...",
  barcodeCode: "Barkod Kodu",
  enterBarcodeCode: "Barkod kodunu giriniz...",
  barcodeCodeDesc: "Barkod ve excel dosyasında kullanılır.",
  barcodeCodeExm: "Örn: Kırmızı için KRM",
  barcodeDetailsError: "Barkod detayları yüklenemedi!",
  barcodeUpdateSuccess: "Barkod başarıyla güncellendi!",
  barcodeUpdateError: "Barkod güncellenirken bir hata oluştu!",
  barcodeDeleteConfirm: "Bu barkodu silmek istediğinize emin misiniz?",
  barcodeDeleteSuccess: "Barkod başarıyla silindi!",
  barcodeDeleteError: "Barkod silinirken bir hata oluştu!",
  deleteBarcode: "Barkodu Sil",
  barcodePartiesError: "Barkoda ait partiler yüklenemedi!",
  partyNum: "Parti Numarası",
  backToBarcodePage: "Barkod Sayfasına Dön",
  clickToUpdate: "Güncellemek İçin Tıklayın!",
  internalServerError: "500 Internal Server Error. Lütfen tekrar deneyin!",
  authenticationFailed: "Kimlik doğrulama başarısız!",
  authorizationFailed: "Yetki doğrulama başarısız!",
  notFoundError: "Kayıt bulunamadı!",
  badGatewayError: "Sistem cevap vermiyor. Lütfen daha sonra tekrar deneyin!",
  somethingWrong: "Bir hata meydana geldi!",
  samePartyNumError:
    "Aynı parti numarasını bu yıl içerisinde zaten kullandınız.",
  sameRollNoError:
    "Lütfen daha önce aynı Roll No'yu girip girmediğinizi kontrol edin.",
  partyNumMinValueError: "Parti numarası minimum 1001 olmalıdır!",
  apiUrl: "https://" + process.env.REACT_APP_API_URL + "/",
  excelFileCreationStarted:
    "Excel oluşturma işlemi başladı! İşlem bittiğinde bilgilendirileceksiniz.",
  excelFileReady: "Excel dosyanız hazır! İndirmek için tıklayın.",
  excelFileReadyError: "Excel dosyası oluşturulurken bir hata meydana geldi!",
  barcodeUpdateStarted:
    "Barkod güncelleme işlemi başladı! İşlem bittiğinde bilgilendirileceksiniz.",
};
