// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/auth-bg-image.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-container {\n  max-width: 100% !important;\n  height: 100vh;\n  max-height: -webkit-fill-available;\n  background-size: cover;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.login-form-container {\n  min-height: 100%;\n  min-height: 70vh;\n}\n\n.footer-text {\n  color: white;\n  font-size: 14px;\n  position: absolute;\n  bottom: 10px;\n  text-shadow: 1px 1px 10px white;\n  margin-bottom: 0px;\n}\n\n@media (min-width: 768px) {\n  .login-form {\n    min-width: 600px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/styles/Authentication/main.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,kCAAkC;EAClC,sBAAsB;EACtB,yDAA2D;AAC7D;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".main-container {\n  max-width: 100% !important;\n  height: 100vh;\n  max-height: -webkit-fill-available;\n  background-size: cover;\n  background-image: url(\"../../assets/img/auth-bg-image.jpg\");\n}\n\n.login-form-container {\n  min-height: 100%;\n  min-height: 70vh;\n}\n\n.footer-text {\n  color: white;\n  font-size: 14px;\n  position: absolute;\n  bottom: 10px;\n  text-shadow: 1px 1px 10px white;\n  margin-bottom: 0px;\n}\n\n@media (min-width: 768px) {\n  .login-form {\n    min-width: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
